<template>
  <div id="container">

    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生クラスの変更</span></h2>
          <p class="lead_txt">
            このページではクラス・お支払い方法の変更のお申込みを受け付けております。
          </p>

          <template v-if="current_user.rank_bank_fee" >
            <p class="lead_txt red bold large">※お支払い方法に銀行振込をご利用の方は、こちらのページからの塾生クラスの変更はできません。<br>下の「お問い合わせ」ボタンからお問い合わせいただくか、複眼経済塾事務局までお電話（03-6280-8451）にてお問い合わせください。</p>
            <div class="button_wrap mt20">
              <p class="alnC bold mb10">こちらのボタンからお問い合わせください</p>
              <router-link to="/contacts/" class="submit basic arrow large">お問い合わせ</router-link>
            </div>
            <p class="alnC sp_alnL bold mt20 mb10">
              お問い合わせはお電話でも承っております<br class="sp">（受付時間 平日 10：00〜17：00）
            </p>
            <div class="contact_tel">
              <a href="tel:0362808451" class="flex">
                <span class="icon_tel">03-6280-8451</span>
              </a>
            </div>
          </template>

          <template v-else>
            <div class="change_contact flex" >
              <p>クラス・お支払い方法の変更についてご不明な点や相談したいことがある場合は、こちらの「お問い合わせ」ボタンよりお知らせください。</p>
              <div>
                <router-link to="/contacts/" class="submit light skeleton">お問い合わせ</router-link>
              </div>
            </div>
          </template>
        </div>

        <div class="cts_box">
          <h2 class="min_ttl"><span>現在のご利用内容</span></h2>
          <div class="usage_details flex">
            <div class="class"><span>現在のクラス</span>{{ current_user.rank_label }}</div>
            <div class="payment"><span>お支払い方法</span>{{ current_user.payment_monthly ? '月額払い' : '年額払い' }}</div>
            <div class="update" v-if="false /*!current_user.payment_monthly*/ ">
              <span>次回の更新月</span>{{ moment(current_user.next_payment_date, 'YYYY年MM月') }}
            </div>
          </div>
        </div>

        <div class="cts_box" v-if="current_user.sns_rank === 'rank_regular'">
          <h2 class="min_ttl"><span>「会社四季報ONLINE」オプション</span></h2>
          <p class="blue bold size20">
            本科の方は「会社四季報ONLINE」の塾生割引をご利用いただけます。年割プランとなりますので、月謝のお支払いを月額払いの方は年額払いにご変更ください。
          </p>
          <div class="btn_wrap mt20">
            <router-link :to="{name: 'UsersCustomerNoJoinRankChangeNew', params: {new_rank: 'regular'}}" class="submit light h_auto sp_h_auto" v-bind:class="{disabled: current_user.rank_bank_fee}">「会社四季報ONLINE」<br>オプションの追加・変更
            </router-link>
          </div>
        </div>

        <div class="cts_box sp_mb">
          <h2 class="min_ttl"><span>変更したいクラスを選択してください</span></h2>
          <p>
            変更を希望するクラスの「このクラスに変更」ボタンを押してください。
          </p>
        </div>

        <div class="cts_box">
          <div class="matrix flex">

            <div class="matrix_col pc">
              <div class="matrix_cell class_select">
              </div>
              <div class="matrix_cell flex gaiyo-title">
                <h4>概要</h4>
              </div>
              <div class="matrix_cell flex">
                <h4>月例会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>懇親会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>各ワークショップ</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>録画講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>複眼IRミーティング</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>コンテスト</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>合宿・ツアー</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>東洋経済会社四季報ONLINE</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定イベント</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell class_select">
              </div>
            </div><!--//matrix_col pc-->

            <!--予科-->
            <div class="matrix_col" v-bind:class="{selected: current_user.sns_rank === 'rank_preparatory'}">
              <!--自分のクラスの時 selected -->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>予科</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">4,950<span>円</span></div>
                    <div class="left pc">&nbsp;</div>
                    <div class="right pc">&nbsp;</div>
                    <span class="month_price pc">&nbsp;</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: 'UsersCustomerNoJoinRankChangeNew', params: {new_rank: 'preparatory'}}"
                               class="btn" v-bind:class="{disabled: current_user.sns_rank === 'rank_preparatory' || current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_preparatory'">選択できません</template>
                    <template v-else>このクラスに変更</template>
                  </router-link><!--予科は月額しかないのでdisabled追加-->
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">初級の方、これから投資を始める方など、基礎を学びたい方の講義に特化したコース。基礎コースのため、複眼経済塾の主軸講義である「月例会」等はご覧いただけません。まずは初歩を学んでから本科に進みたい方にお薦め。
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>都度受講</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>初級のみ</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>予科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">4,950<span>円</span></div>
                      <div class="left pc">&nbsp;</div>
                      <div class="right pc">&nbsp;</div>
                      <span class="month_price pc">&nbsp;</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: 'UsersCustomerNoJoinRankChangeNew', params: {new_rank: 'preparatory'}}"
                                 class="btn" v-bind:class="{disabled: current_user.sns_rank === 'rank_preparatory' || current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_preparatory'">選択できません</template>
                      <template v-else>このクラスに変更</template>
                    </router-link><!--予科は月額しかないのでdisabled追加-->
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 予科-->

            <!--本科-->
            <div class="matrix_col"
                 v-bind:class="{selected: current_user.sns_rank === 'rank_regular' || current_user.sns_rank === 'rank_regular_default'}">
              <!--自分のクラスの時 selected-->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3 v-if="current_user.sns_rank === 'rank_regular_default' || current_user.old_rank_default">本科（旧月謝適用中）</h3>
                  <h3 v-else>本科</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex" v-if="current_user.sns_rank !== 'rank_regular_default' && !current_user.old_rank_default">
                    <div class="left">月額払い</div>
                    <div class="right">16,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">162,000<span>円</span></div>
                    <span class="month_price">（月額換算：13,500円）</span>
                  </div>
                  <div class="price flex" v-else>
                    <div class="left">月額払い</div>
                    <div class="right">10,000<span>円</span></div>
                    <div class="left">&nbsp;</div>
                    <div class="right">&nbsp;</div>
                    <span class="month_price">&nbsp;</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: current_user.old_rank_default ? 'UsersCustomerNoJoinRankChangeDefault' : 'UsersCustomerNoJoinRankChangeNew' , params: {new_rank: 'regular'}}" class="btn"
                               v-bind:class="{disabled: current_user.sns_rank === 'rank_regular_default' || current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_regular_default'">選択できません</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular' && current_user.payment_monthly">支払い方法の変更</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular'">支払い方法の変更</template>
                    <template v-else-if="isDowngrade('rank_regular')">このクラスに変更</template>
                    <template v-else>このクラスに変更</template>
                  </router-link>
                  <!--自分がこのクラスの場合、テキストは「支払い方法の変更」になる-->
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">複眼の主軸コース。塾生の８割がこのコースに在籍。最重要講義である「月例会」や「複眼ポートフォリオレビュー」など、投資を実践するための講義が満載。合宿、ツアーや懇親会も本科より参加いただけます。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>都度受講</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3 v-if="current_user.sns_rank === 'rank_regular_default' || current_user.old_rank_default">本科（旧月謝適用中）</h3>
                    <h3 v-else>本科</h3>
                    <div class="price flex" v-if="current_user.sns_rank !== 'rank_regular_default' && !current_user.old_rank_default">
                      <div class="left">月額払い</div>
                      <div class="right">16,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">162,000<span>円</span></div>
                      <span class="month_price">（月額換算：13,500円）</span>
                    </div>
                    <div class="price flex" v-else>
                      <div class="left">月額払い</div>
                      <div class="right">10,000<span>円</span></div>
                      <div class="left">&nbsp;</div>
                      <div class="right">&nbsp;</div>
                      <span class="month_price">&nbsp;</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: current_user.old_rank_default ? 'UsersCustomerNoJoinRankChangeDefault' : 'UsersCustomerNoJoinRankChangeNew' , params: {new_rank: 'regular'}}" class="btn"
                                 v-bind:class="{disabled: current_user.sns_rank === 'rank_regular_default' || current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_regular_default'">選択できません</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular' && current_user.payment_monthly">支払い方法の変更</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular'">支払い方法の変更</template>
                      <template v-else-if="isDowngrade('rank_regular')">このクラスに変更</template>
                      <template v-else>このクラスに変更</template>
                    </router-link>
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 本科-->

            <!--本科プラス-->
            <div class="matrix_col" v-bind:class="{selected: current_user.sns_rank === 'rank_regular_plus'}">
              <!--自分のクラスの時 selected-->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>本科プラス</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">27,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">264,000<span>円</span></div>
                    <span class="month_price">（月額換算：22,000円）</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: 'UsersCustomerNoJoinRankChangeNew', params: {new_rank: 'regular_plus'}}" class="btn" v-bind:class="{disabled: current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_regular_plus' && current_user.payment_monthly">支払い方法の変更</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular_plus'">支払い方法の変更</template>
                    <template v-else-if="isDowngrade('rank_regular_plus')">このクラスに変更</template>
                    <template v-else>このクラスに変更</template>
                  </router-link>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">最上級コース。全てのワークショップが追加料金無しで受け放題。全イベント優先申込。プラス限定イベントと講義に、四季報ONLINEプレミアムが付いて、このお値段はお得！最もディープに複眼を使いこなしたいなら本科プラス。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="https://www.millioneyes.jp/medias/images/mark_star.png" alt="☆" class="star"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>本科プラス</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">27,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">264,000<span>円</span></div>
                      <span class="month_price">（月額換算：22,000円）</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: 'UsersCustomerNoJoinRankChangeNew', params: {new_rank: 'regular_plus'}}" class="btn" v-bind:class="{disabled: current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_regular_plus' && current_user.payment_monthly">支払い方法の変更</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular_plus'">支払い方法の変更</template>
                      <template v-else-if="isDowngrade('rank_regular_plus')">このクラスに変更</template>
                      <template v-else>このクラスに変更</template>
                    </router-link>
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 本科プラス-->

          </div>

          <ul class="caution_num" v-if="false">
            <li>四季報10倍ワークショップは教室版とオンライン版があり、価格が異なります。詳細は<a href="https://www.millioneyes.jp/workshop_shikiho/"
                                                           target="_blank">こちら</a>をご覧ください。
            </li>
            <li>新型コロナウィルス感染拡大防止の為開催されない場合があります。</li>
          </ul>

        </div>

        <div class="cts_box">
          <h3 class="min_ttl">「会社四季報ONLINE」について</h3>
          <p>
            【会社四季報ONLINE】については <a href="https://www.millioneyes.jp/shikiho_online/">こちら</a>をご覧ください。
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">月謝のお支払い方法についてのご注意</h3>
          <div class="payment_notice flex">
            <div class="payment_notice_box">
              <ul class="dotted">
                <li class="ttl">
                  <h4>月額払いをご希望の場合</h4>
                </li>
                <li>毎月１日に月謝の引き落とし決済を、ご登録クレジットカードにて行います。</li>
                <li>退会は、各月にいつでも行う事ができます。退会申請日の月末までご利用いただけます。</li>
                <li> 東洋経済新報社の会社四季報ONLINE特別割引は、「本科（年払）」の方のみ選択が可能です。「本科（月払）」「予科（月払）」を選択の方は、会社四季報ONLINE特別割引をオプション契約することはできません。ご希望の方は、「本科（年払）」にクラスを変更してお申込みください。 </li>
                <li>「本科プラス（月払）」には、会社四季報ONLINEプレミアムコースが自動的に付与されます。</li>
              </ul>
            </div>
            <div class="payment_notice_box">
              <ul class="dotted">
                <li class="ttl">
                  <h4>年額払いをご希望の場合</h4>
                </li>
                <li>年額払いは、入塾日の最初の月の１日に１年分の月謝をまとめてご登録クレジットカードより引き落としいたします。</li>
                <li> 年払割引が適用されておりますので、年途中での退会やクラス変更ができません。退会は年払の満了日（7月入塾の場合、翌年6月末日）での退会のみとなります。十分ご検討の上、年額払いにお申込みください。 </li>
                <li> 東洋経済新報社の会社四季報ONLINE特別割引は、「本科（年払）」の方のみ選択が可能です。年途中でのオプション申込が可能です。ただし、オプションを外したい場合は、年途中での解約ができません。予めご了承ください。 </li>
                <li>「本科プラス（年払）」には、会社四季報ONLINEプレミアムコースが自動的に付与されます。</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="cts_box">
          <h2 class="min_ttl"><span>クラス変更について</span></h2>
          <p>
            クラス変更については <a href="https://www.millioneyes.jp/class_matrix/">こちら</a>をご覧ください。
          </p>
        </div>

        <div class="btn_wrap">
          <p class="alnC sp_alnL mb10">クラス・お支払い方法の変更をしない場合はこちらのボタンからマイページにお戻りください。</p>
          <router-link :to="{name: 'UsersCustomerAccount'}" class="submit light skeleton arrow back large">マイページにもどる
          </router-link>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li><a href="#">塾生クラスの変更</a></li>
      </ul>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  methods: {
    isDowngrade(new_rank) {
      const scores = {
        rank_regular_plus: 2,
        rank_regular: 1,
        rank_regular_default: 1,
        rank_preparatory: 0,
      }
      return (scores[new_rank] - scores[this.current_user.sns_rank]) < 0
    },
  },

  mounted() {
    // form token
    this.setFormToken('RankChange')

    if (!this.current_user.no_join_rank_change_enabled) {
      this.$router.push({name: 'UsersCustomerAccount'})
      return
    }

    this.$store.state.change_rank_info = null
    // for jquery.
    window.$(".matrix .info span").on("click", function () {
      if (window.$(this).hasClass("open")) {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
        window.$(this).removeClass("open");
      } else {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeIn(300);
        window.$(this).addClass("open");
      }
    });
    window.$(".matrix .info_box .colse_btn,.matrix .info_box a").on("click", function () {
      window.$(this).closest(".matrix_cell").find(".info span").removeClass("open");
      window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
    })
    window.$(".matrix .tggle_btn").on("click", function () {
      window.$(this).closest(".matrix_col");
      window.$(this).closest(".matrix_col").find(".sp_open_cts").slideToggle();
      window.$(this).closest(".matrix_col").toggleClass("open");
    });
  }
}
</script>
